import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { getTranslation } from 'helpers/getTranslation';

import { PluginPreviewWrapper } from 'components/PluginPreviewWrapper';

import { Header } from 'components/Header';

import styles from './CoursePreviewLayout.styles.scss';

const CoursePreviewLayout = ({
  className,
  children,
  onExitPreviewClick,
  setPreviewType,
}) => (
  <div className={cn(styles.layout, className)}>
    <Header />
    <div className={styles.toolbar}>
      <Button
        size="md"
        variant="outlined"
        startIcon={<Icon name="chevronLeft" />}
        onClick={onExitPreviewClick}
      >
        {getTranslation('common.button.exit_preview')}
      </Button>
    </div>
    <div className={styles.inner}>
      <PluginPreviewWrapper size="sm">{children}</PluginPreviewWrapper>
    </div>
    {/* <Tabs
      className={styles.tabs}
      innerClassName={styles.inner}
      setPreviewType={setPreviewType}
      textClassName={styles.tabsText}
      onTabChange={setPreviewType}
      startActions={[
        {
          component: (
            <Button
              size="md"
              variant="outlined"
              startIcon={<Icon name="chevronLeft" />}
              onClick={onExitPreviewClick}
            >
              {getTranslation('common.button.exit_preview')}
            </Button>
          ),
        },
      ]}
      actions={[
        {
          component: (
            <div className={styles.actions}>
              <Typography variant="body2" className={styles.actionText}>
                {getTranslation('common.text.test_mode')}
              </Typography>
            </div>
          ),
        },
      ]}
      items={[
        {
          label: getTranslation('common.text.web'),
          content: (
            <WebPreviewWrapper isCoursePreview={true}>
              {children}
            </WebPreviewWrapper>
          ),
        },
        {
          label: getTranslation('common.text.plugin'),
          content: (
            <PluginPreviewWrapper size="sm">{children}</PluginPreviewWrapper>
          ),
        },
      ]}
    /> */}
  </div>
);

export default React.memo(CoursePreviewLayout);
