export const typeOptions = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Union',
    value: 'union',
  },
  {
    label: 'Subtract',
    value: 'subtract',
  },
  {
    label: 'Exclude',
    value: 'exclude',
  },
  {
    label: 'Intersect',
    value: 'intersect',
  },
];
