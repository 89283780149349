import * as api from 'api/methods';

import { FIGMA_LAYER_TYPES } from 'constants/common';

import { transformLayers } from './transformLayers';

export const getDesignInitialState = async ({
  fileId,
  filePage,
  currentInitialState,
}) => {
  try {
    const initialStateLayer = filePage.children.find(
      (layer) =>
        layer.type === FIGMA_LAYER_TYPES.SECTION &&
        layer.name === 'Initial State',
    );

    if (!initialStateLayer) {
      return { initialState: null };
    }

    const preview = await api
      .getFigmaNodePreview({
        fileId,
        nodeId: initialStateLayer.id,
        format: 'jpg',
      })
      .then((previewData) => {
        if (previewData.err) {
          return null;
        }

        return previewData.images[initialStateLayer.id];
      });

    const initialState = {
      preview,
      componentsLayer: null,
      layers: transformLayers({
        layers: initialStateLayer.children,
        currentLayers: currentInitialState ? currentInitialState.layers : [],
        parent: null,
      }),
    };

    return { initialState };
  } catch (error) {
    console.warn(error);
  }
};
