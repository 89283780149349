import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import { convertDataToApi } from './CreateCourse.helpers';

import CreateCourse from './CreateCourse';

const CreateCourseContainer = ({ open, ...restProps }) => {
  const navigate = useNavigate();

  const [createLoading, setCreateLoading] = useState(false);

  const { data: schools } = useFetch(
    {
      defaultData: [],
      fetcher: api.getSchools,
      immediately: false,
      stopRequest: !open,
    },
    null,
    [open],
  );

  const handleCreateClick = async (values, { resetForm }) => {
    setCreateLoading(true);

    try {
      const course = await api.createCourse(convertDataToApi(values));

      resetForm();

      navigate(`/courses/${course.id}`);
    } catch (error) {
      console.warn(error); // TODO: catch error
    }

    setCreateLoading(false);
  };

  const schoolOptions = schools.map((school) => ({
    label: `${school.name} (${school.language})`,
    value: school.id,
  }));

  return (
    <CreateCourse
      {...restProps}
      open={open}
      createLoading={createLoading}
      schoolOptions={schoolOptions}
      onCreateClick={handleCreateClick}
    />
  );
};

export default React.memo(CreateCourseContainer);
