import * as Yup from 'yup';

export const initialValues = {
  withCertificate: false,
  trial: false,
};

export const getInitialValues = (course) => {
  if (!course) {
    return initialValues;
  }

  return {
    withCertificate: course.withCertificate || false,
    trial: course.trial || false,
  };
};

export const validationSchema = Yup.object().shape({
  withCertificate: Yup.boolean(),
  trial: Yup.boolean(),
});
