import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { Table } from 'components/shared/Table';
import { Typography } from 'components/shared/Typography';
import { Avatar } from 'components/shared/Avatar';

import { Tooltip } from 'components/Tooltip';
import { PublishStatusTag } from 'components/PublishStatusTag';
import { CoursePreview } from 'components/CoursePreview';
import { TablePagination } from 'components/TablePagination';
import { SchoolPreview } from 'components/SchoolPreview';

import { getArcadeSubtitle } from './ArcadesTable.helpers';

import styles from './ArcadesTable.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth },
  DefaultCell,
  locale,
) => {
  switch (key) {
    case 'title': {
      return (
        <div
          className={styles.titleCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <CoursePreview className={styles.preview} preview={item.preview} />
          <div>
            <Typography
              className={styles.title}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {item[key] || getTranslation('common.text.untitled')}
            </Typography>
            <Typography
              className={styles.subtitle}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {getArcadeSubtitle(item.platform)}
            </Typography>
          </div>
        </div>
      );
    }
    case 'authors': {
      return (
        <div
          className={styles.authorsCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <div className={styles.authors}>
            {item[key].map((author) => (
              <Tooltip
                key={author.id}
                text={author.name || author.email}
                triggerClassName={styles.authorTrigger}
                triggerElement={
                  <Avatar
                    className={styles.author}
                    size="sm"
                    text={author.name || author.email}
                  />
                }
              />
            ))}
          </div>
        </div>
      );
    }
    case 'school': {
      const school = item.school;

      return (
        <div
          className={styles.schoolCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Tooltip
            key={school.id}
            text={school.name}
            triggerClassName={styles.schoolTrigger}
            triggerElement={
              <SchoolPreview preview={school.preview} name={school.name} />
            }
          />
        </div>
      );
    }
    case 'lastActionAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item[key]
            ? formatDistance(parseISO(item[key]), new Date(), {
                addSuffix: true,
                locale: locale === 'ru' ? ru : enUS,
              })
            : '-'}
        </DefaultCell>
      );
    }
    case 'createdAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {formatDate(item[key], 'dd MMM, yyyy', locale)}
        </DefaultCell>
      );
    }
    case 'status': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <PublishStatusTag variant={item[key].toLowerCase()} />
        </div>
      );
    }
    case 'studentsCount': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key]}
        </DefaultCell>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const ArcadesTable = ({
  className,
  columns,
  locale,
  data,
  params,
  currentPage,
  totalPages,
  perPage,
  onSearchChange,
  onSortChange,
  onPerPageChange,
  onNextPageClick,
  onPrevPageClick,
  onEditClick,
  onRemoveClick,
  onRowClick,
}) => (
  <div className={styles.root}>
    {/* <TableToolbar onSearchChange={onSearchChange} /> */}
    <Table
      className={className}
      columns={columns}
      actions={[
        {
          key: 'edit',
          icon: 'edit',
          tooltipText: 'Edit arcade',
          onClick: onEditClick,
        },
        {
          key: 'remove',
          icon: 'trash',
          tooltipText: 'Remove arcade',
          onClick: onRemoveClick,
        },
      ]}
      data={data}
      params={params}
      cellRenderer={(props, DefaultCell) =>
        cellRenderer(props, DefaultCell, locale)
      }
      onRowClick={(arcade) => onRowClick(arcade.id)}
      onSortChange={onSortChange}
    />
    <TablePagination
      currentPage={currentPage}
      perPage={perPage}
      totalPages={totalPages}
      onPerPageChange={onPerPageChange}
      onNextPageClick={onNextPageClick}
      onPrevPageClick={onPrevPageClick}
    />
  </div>
);

export default React.memo(ArcadesTable);
