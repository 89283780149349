import axios from 'axios';
import qs from 'qs';

export const getFigmaNodePreview = ({ fileId, nodeId, format = 'svg' }) =>
  axios.get(
    `https://api.figma.com/v1/images/${fileId}?${qs.stringify({
      ids: nodeId,
      format,
    })}`,
    {
      api: 'figma',
    },
  );

export const getFigmaFile = (fileId) =>
  axios.get(
    `https://api.figma.com/v1/files/${fileId}?${qs.stringify({
      geometry: 'paths',
    })}`,
    {
      api: 'figma',
    },
  );

export const getFigmaFileImages = (fileId) =>
  axios.get(`https://api.figma.com/v1/files/${fileId}/images`, {
    api: 'figma',
  });

export const getFigmaFileStyles = (fileId) =>
  axios.get(`https://api.figma.com/v1/files/${fileId}/styles`, {
    api: 'figma',
  });

export const getFigmaFileStyleByKey = (styleId) =>
  axios.get(`https://api.figma.com/v1/styles/${styleId}`, {
    api: 'figma',
  });

export const getFigmaImages = (fileId) =>
  axios.get(`https://api.figma.com/v1/images/${fileId}`, {
    api: 'figma',
  });

export const getFigmaFileNodes = ({ fileId, nodeIds }) =>
  axios.get(
    `https://api.figma.com/v1/files/${fileId}/nodes?${qs.stringify({
      ids: nodeIds,
    })}`,
    {
      api: 'figma',
    },
  );
