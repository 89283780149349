import React from 'react';
import cn from 'classnames';
import { DndContext, pointerWithin } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { Popover } from 'components/Popover';
import { DragMenu } from 'components/DragMenu';
import { ContextMenu } from 'components/ContextMenu';
import { SortableItem } from 'components/SortableItem';

import { TextBlock } from './components/TextBlock';
import { ImageBlock } from './components/ImageBlock';
import { VideoBlock } from './components/VideoBlock';
import { DividerBlock } from './components/DividerBlock';
import { SpoilerBlock } from './components/SpoilerBlock';
import { NumberListBlock } from './components/NumberListBlock';
import { MarkerListBlock } from './components/MarkerListBlock';
import { ListWithPropertiesBlock } from './components/ListWithPropertiesBlock';
import { BlockWithValue } from './components/BlockWithValue';

import { contextItems } from './TheoryBlocks.config';

import styles from './TheoryBlocks.styles.scss';

const TheoryBlocks = ({
  addBtnClassName,
  readOnly,
  blocks,
  sensors,
  namePrefix,
  onBlockDragStart,
  onBlockDragEnd,
  onBlockDragOver,
  onBlockClick,
  onRemoveBlockClick,
}) => (
  <>
    {blocks && blocks.length > 0 && (
      <div className={styles.blocks}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={onBlockDragStart}
          onDragEnd={onBlockDragEnd}
          onDragOver={onBlockDragOver}
        >
          <SortableContext items={blocks} strategy={() => null}>
            {blocks.map((block, blockIndex) => (
              <SortableItem
                className={styles.block}
                key={block.id}
                id={block.id}
              >
                {({ listeners }) => {
                  switch (block.type) {
                    case 'heading1':
                    case 'heading2':
                    case 'heading3':
                    case 'text': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <TextBlock
                            readOnly={readOnly}
                            type={block.type}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'spoiler': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <SpoilerBlock
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'numberList': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <NumberListBlock
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'markerList': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <MarkerListBlock
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'listWithProperties': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <ListWithPropertiesBlock
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'blockWithValue': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <BlockWithValue
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'image': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <ImageBlock
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'video': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <VideoBlock
                            readOnly={readOnly}
                            namePrefix={`${namePrefix}[${blockIndex}]`}
                          />
                        </div>
                      );
                    }
                    case 'divider': {
                      return (
                        <div className={styles.block} key={block.id}>
                          {!readOnly && (
                            <DragMenu
                              className={styles.blockMenu}
                              dragListeners={listeners}
                              onRemoveClick={() => onRemoveBlockClick(block)}
                            />
                          )}
                          <DividerBlock />
                        </div>
                      );
                    }
                  }
                }}
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    )}
    {!readOnly && (
      <Popover
        placement="right-end"
        triggerElement={({ isMounted }) => (
          <Button
            className={cn(
              styles.addBtn,
              { [styles.addBtnActive]: isMounted },
              addBtnClassName,
            )}
            size="sm"
            variant="link"
            startIcon={<Icon name="plus" />}
          >
            {getTranslation('common.button.add_block')}
          </Button>
        )}
      >
        <ContextMenu
          className={styles.addMenu}
          items={contextItems}
          onItemClick={onBlockClick}
        />
      </Popover>
    )}
  </>
);

export default React.memo(TheoryBlocks);
