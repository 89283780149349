import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Select } from 'components/shared/Select';
import { Button } from 'components/shared/Button';

import { Popover } from 'components/Popover';

import { SortButton } from '../SortButton';

import styles from './Actions.styles.scss';

const Actions = ({ onFiltersClick, onSortChange, sortParams }) => {
  return (
    <div className={styles.actions}>
      <Popover
        mode="controlled"
        placement="bottom-start"
        triggerElement={() => (
          <Button
            className={styles.action}
            variant="link"
            size="md"
            startIcon={
              <Icon
                name={
                  sortParams.sortDirection === 'asc' ? 'arrowUp' : 'arrowDown'
                }
              />
            }
          >
            {getTranslation('inbox_page.action.sort_by')}:{' '}
            {sortParams.sortBy.label}
          </Button>
        )}
      >
        <div>
          <div className={styles.form}>
            <Select
              className={styles.select}
              value={sortParams.sortBy}
              options={[
                {
                  label: getTranslation('inbox_page.action.date'),
                  value: 'createdAt',
                },
              ]}
              onChange={(event) =>
                onSortChange({
                  sortBy: event,
                })
              }
            />
            <div className={styles.sortActions}>
              <SortButton
                variant={sortParams.sortDirection === 'asc' && 'active'}
                startIcon={<Icon name="arrowUp" />}
                text={getTranslation('inbox_page.action.ascending')}
                disabled={sortParams.sortDirection === 'asc'}
                onClick={() => {
                  onSortChange({ sortDirection: 'asc' });
                }}
              />
              <SortButton
                variant={sortParams.sortDirection === 'desc' && 'active'}
                startIcon={<Icon name="arrowDown" />}
                text={getTranslation('inbox_page.action.descending')}
                disabled={sortParams.sortDirection === 'desc'}
                onClick={() => {
                  onSortChange({ sortDirection: 'desc' });
                }}
              />
            </div>
          </div>
        </div>
      </Popover>

      {/* <Button
        className={styles.action}
        variant="link"
        size="md"
        startIcon={<Icon name="filter" />}
        onClick={() => {
          onFiltersClick();
        }}
      >
        Filter
      </Button>
      <Button
        className={styles.action}
        variant="link"
        size="md"
        startIcon={<Icon name="search" className={styles.searchIcon} />}
      /> */}
    </div>
  );
};

export default React.memo(Actions);
