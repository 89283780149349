import React from 'react';
import cn from 'classnames';

import styles from './Popover.styles.scss';

const PLACEMENTS = {
  'right-end': styles.popoverRightEnd,
  'left-end': styles.popoverLeftEnd,
  'bottom-start': styles.popoverBottomStart,
};

const Popover = ({
  className,
  triggerClassName,
  isMounted,
  refs,
  animationStyles,
  styleProps,
  mode,
  placement,
  triggerElement,
  children,
  getReferenceProps,
  getFloatingProps,
  onPopoverMouseDown,
}) => (
  <>
    <div
      className={cn(styles.trigger, triggerClassName)}
      ref={refs.setReference}
      {...getReferenceProps({ onClick: (event) => event.stopPropagation() })}
    >
      {triggerElement({ isMounted })}
    </div>
    {/* <FloatingPortal> */}
    {isMounted && (
      <div
        className={cn(styles.popover, PLACEMENTS[placement], className)}
        ref={refs.setFloating}
        style={{ ...styleProps, ...animationStyles }}
        {...getFloatingProps()}
        onMouseDown={mode === 'uncontrolled' ? onPopoverMouseDown : () => {}}
      >
        {children}
      </div>
    )}
    {/* </FloatingPortal> */}
  </>
);

export default React.memo(Popover);
