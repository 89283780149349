import React from 'react';

import { Typography } from 'components/shared/Typography';

import { TheoryBlocks } from 'components/TheoryBlocks';

import styles from './TaskByDescriptionSettings.styles.scss';

const TaskByDescriptionSettings = () => (
  <div className={styles.settings}>
    <Typography
      className={styles.label}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      Description
    </Typography>
    <TheoryBlocks addBtnClassName={styles.addBtn} namePrefix="description" />
  </div>
);

export default React.memo(TaskByDescriptionSettings);
