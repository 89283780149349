import React from 'react';

import { Typography } from 'components/shared/Typography';

import { TypesSelect } from 'components/TypesSelect';

import styles from './KindSettings.styles.scss';

const KindSelect = ({ onOptionClick }) => (
  <div className={styles.select}>
    <Typography className={styles.title} variant="subtitle">
      Select type
    </Typography>
    <TypesSelect
      options={[
        {
          icon: 'stepByStep',
          text: 'Step By Step',
          value: 'STEP_BY_STEP',
        },
        {
          icon: 'imageText',
          text: 'By task description',
          value: 'BY_TASK_DESCRIPTION',
        },
        {
          icon: 'imageText',
          text: 'By reference',
          value: 'BY_REFERENCE',
        },
      ]}
      onOptionClick={onOptionClick}
    />
  </div>
);

export default React.memo(KindSelect);
