import React from 'react';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SwitchField } from 'components/FormikFields';

import styles from './StylesSection.styles.scss';

const StylesSection = ({ readOnly, namePrefix }) => {
  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <FormikField
          withControl={false}
          name={`${namePrefix}.checkStyles`}
          component={SwitchField}
          componentProps={{ readOnly, type: 'checkbox' }}
        />
        <Typography
          className={styles.headerText}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          Check styles
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(StylesSection);
