export const getDefaultStep = (lesson) => {
  if (!lesson.kind) {
    return 1;
  }

  if (lesson.kind && (!lesson.fileId || !lesson.filePageId)) {
    return 2;
  }

  return 3;
};
