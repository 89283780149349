import React from 'react';

import { Typography } from 'components/shared/Typography';

import { TheoryBlocks } from 'components/TheoryBlocks';
import { FormikField } from 'components/FormikField';
import { UploadField, EditableTextField } from 'components/FormikFields';

import styles from './TaskByReferenceSettings.styles.scss';

const TaskByReferenceSettings = () => (
  <div className={styles.settings}>
    <Typography
      className={styles.label}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      Description
    </Typography>
    <TheoryBlocks addBtnClassName={styles.addBtn} namePrefix="description" />
    <div className={styles.divider} />
    <Typography
      className={styles.label}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      Result design
    </Typography>
    <FormikField
      name="resultRefPreview"
      withControl={false}
      component={UploadField}
      componentProps={{
        className: styles.preview,
        variant: 'imageBlock',
        accept: {
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
        },
      }}
    />
    <Typography
      className={styles.label}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      Percent to complete
    </Typography>
    <FormikField
      name="percentToComplete"
      withControl={false}
      component={EditableTextField}
      componentProps={{
        className: styles.percentToComplete,
        variant: 'text',
        placeholder: '100%',
      }}
    />
  </div>
);

export default React.memo(TaskByReferenceSettings);
