import React from 'react';
import cn from 'classnames';
import { useFormikContext, getIn } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { EditableTextField, SelectField } from 'components/FormikFields';

import { propertiesOptions } from './ListWithPropertiesBlock.config';

import styles from './ListWithPropertiesBlock.styles.scss';

const ListWithPropertiesBlock = ({ readOnly, namePrefix, onKeyDown }) => {
  const { values } = useFormikContext();

  const items = getIn(values, `${namePrefix}.data`);

  return (
    <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
      <FormikField
        name={`${namePrefix}.title`}
        withControl={false}
        component={EditableTextField}
        componentProps={{
          className: styles.blockTitle,
          readOnly,
          variant: 'text',
          placeholder: getTranslation(
            'components.theory_blocks.list_placeholder',
          ),
        }}
      />
      <FormikField
        name={`${namePrefix}.text`}
        withControl={false}
        component={EditableTextField}
        componentProps={{
          className: styles.blockText,
          readOnly,
          variant: 'text',
          placeholder: getTranslation(
            'components.theory_blocks.list_placeholder',
          ),
        }}
      />
      <div className={styles.items}>
        {items.map((_, itemIndex) => (
          <div className={styles.item}>
            <div className={styles.itemOrder}>
              <Typography
                className={styles.itemOrderText}
                variant="body3"
                mode="compact"
                weight="medium"
              >
                {itemIndex + 1}
              </Typography>
            </div>
            <FormikField
              name={`${namePrefix}.data[${itemIndex}].text`}
              withControl={false}
              component={EditableTextField}
              componentProps={{
                className: styles.itemText,
                readOnly,
                variant: 'text',
                autoFocus: true,
                placeholder: getTranslation(
                  'components.theory_blocks.list_placeholder',
                ),
                onKeyDown: (event) => onKeyDown(event, itemIndex),
              }}
            />
            <FormikField
              name={`${namePrefix}.data[${itemIndex}].property.type`}
              withControl={false}
              component={SelectField}
              componentProps={{
                className: styles.itemPropertyType,
                options: propertiesOptions,
                readOnly,
              }}
            />
            <FormikField
              name={`${namePrefix}.data[${itemIndex}].property.value`}
              withControl={false}
              component={EditableTextField}
              componentProps={{
                className: styles.itemText,
                readOnly,
                variant: 'text',
                placeholder: getTranslation(
                  'components.theory_blocks.list_placeholder',
                ),
                onKeyDown: (event) => onKeyDown(event, itemIndex),
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ListWithPropertiesBlock);
