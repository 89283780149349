export const propertiesOptions = [
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Color',
    value: 'color',
  },
  {
    label: 'Star Ratio',
    value: 'starRatio',
  },
  {
    label: 'Star Points Count',
    value: 'starPointsCount',
  },
  {
    label: 'Ellipse Start',
    value: 'ellipseStart',
  },
  {
    label: 'Stroke Weight',
    value: 'strokeWeight',
  },
  {
    label: 'Start Point and End Point',
    value: 'startPointEndPoint',
  },
  {
    label: 'Corner Radius',
    value: 'cornerRadius',
  },
  {
    label: 'Shadow Effect',
    value: 'shadowEffect',
  },
  {
    label: 'Auto Layout Direction',
    value: 'autoLayoutDirection',
  },
  {
    label: 'Auto Layout Align',
    value: 'autoLayoutAlign',
  },
  {
    label: 'Auto Layout Horizontal Gap',
    value: 'autoLayoutHorizontalGap',
  },
  {
    label: 'Auto Layout Vertical Gap',
    value: 'autoLayoutVerticalGap',
  },
  {
    label: 'Auto Layout Left Padding',
    value: 'autoLayoutLeftPadding',
  },
  {
    label: 'Auto Layout Right Padding',
    value: 'autoLayoutRightPadding',
  },
  {
    label: 'Auto Layout Top Padding',
    value: 'autoLayoutTopPadding',
  },
  {
    label: 'Auto Layout Bottom Padding',
    value: 'autoLayoutBottomPadding',
  },
];
