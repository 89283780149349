import React from 'react';
import { Formik } from 'formik';

import { STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';
import { getLessonStatusContextItems } from 'helpers/getLessonStatusContextItems';
import { getReadonlyLessonText } from 'helpers/getReadonlyLessonText';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { AutoSave } from 'components/AutoSave';
import { CourseTree } from 'components/CourseTree';
import { LessonLayout } from 'components/Layout';
import { LessonHeader } from 'components/LessonHeader';
import { ToolbarActions } from 'components/ToolbarActions';
import { ReadOnlySection } from 'components/ReadOnlySection';
import { BreadcrumbsToolbar } from 'components/Toolbar';

import { LessonsSection } from './components/LessonsSection';
import { MainSettings } from './components/MainSettings';
import { FigmaFileSettings } from './components/FigmaFileSettings';
import { ResyncSection } from './components/ResyncSection';
import { TasksSection } from './components/TasksSection';
import { GoodToKnowSection } from './components/GoodToKnowSection';
import { GoodToKnowSettings } from './components/GoodToKnowSettings';
import { KindSettings } from './components/KindSettings';
import { TaskByReferenceSettings } from './components/TaskByReferenceSettings';
import { TaskByDescriptionSettings } from './components/TaskByDescriptionSettings';

import { convertDataToForm } from './helpers/convertDataToForm';
import { convertDataToApi } from './helpers/convertDataToApi';

import styles from './FigmaLessonPage.styles.scss';

const FigmaLessonPage = ({
  loading,
  saveLoading,
  resyncLoading,
  continueLoading,
  lessonsOpen,
  readOnly,
  figmaFileImages,
  lesson,
  lastActionAt,
  currentStep,
  currentTask,
  courseTitle,
  courseStatus,
  setCurrentStep,
  onAutoSaveSubmit,
  onTaskClick,
  onInitialStateClick,
  onTaskByDescriptionClick,
  onTaskByReferenceClick,
  onGoodToKnowClick,
  onContinueCreationClick,
  onSaveClick,
  onResyncClick,
  onPreviewClick,
  onRemoveClick,
  onLessonsCloseClick,
  onLessonsBtnClick,
  onStatusContextItemClick,
}) => (
  <Formik
    enableReinitialize
    validateOnMount
    initialValues={convertDataToForm(lesson, readOnly)}
    onSubmit={onAutoSaveSubmit}
  >
    {({ values, setFieldValue }) => (
      <LessonLayout
        sidebarComponent={
          <div className={styles.sidebar}>
            {currentStep === 3 ? (
              <>
                <div className={styles.header}>
                  <Button
                    className={styles.lessonsBtn}
                    size="sm"
                    variant="link"
                    startIcon={<Icon name="lessonsTree" />}
                    onClick={onLessonsBtnClick}
                  >
                    {getTranslation('common.button.lessons')}
                  </Button>
                  <LessonsSection
                    open={lessonsOpen}
                    onClose={onLessonsCloseClick}
                  />
                  <ResyncSection
                    loading={resyncLoading}
                    readOnly={readOnly}
                    onResyncClick={() =>
                      onResyncClick(values, { setFieldValue })
                    }
                  />
                </div>
                <TasksSection
                  tasks={values.tasks}
                  currentTask={currentTask}
                  kind={lesson.kind}
                  designType={values.designType}
                  onTaskClick={onTaskClick}
                  onInitialStateClick={onInitialStateClick}
                  onTaskByReferenceClick={onTaskByReferenceClick}
                  onTaskByDescriptionClick={onTaskByDescriptionClick}
                />
                <GoodToKnowSection onClick={onGoodToKnowClick} />
              </>
            ) : (
              <CourseTree />
            )}
          </div>
        }
        toolbarComponent={
          <BreadcrumbsToolbar
            breadcrumbsData={{ courseTitle, lessonTitle: values.title }}
            endAdornment={
              currentStep === 3 ? (
                <ToolbarActions
                  withRemove
                  saveLoading={saveLoading}
                  contextItems={getLessonStatusContextItems({
                    courseReadOnly: courseStatus === STATUSES.PUBLISHED,
                    status: lesson.status,
                  })}
                  removeData={{ id: lesson.id, title: lesson.title }}
                  updatedAt={lastActionAt}
                  status={lesson.status}
                  onSaveClick={() => onSaveClick(values)}
                  onPreviewClick={onPreviewClick}
                  onRemoveClick={onRemoveClick}
                  onStatusContextItemClick={(contextItem) =>
                    onStatusContextItemClick(
                      contextItem,
                      convertDataToApi(values),
                    )
                  }
                />
              ) : (
                values.filePageId && (
                  <Button
                    className={styles.action}
                    size="md"
                    startIcon={
                      continueLoading && (
                        <Loading size={18} color="var(--color-white-primary)" />
                      )
                    }
                    endIcon={<Icon name="chevronRight" />}
                    onClick={() =>
                      onContinueCreationClick(values, { setFieldValue })
                    }
                  >
                    {getTranslation('common.button.continue_creation')}
                  </Button>
                )
              )
            }
          />
        }
      >
        <div className={styles.page}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <AutoSave debounceMs={1000} />
              {readOnly && (
                <ReadOnlySection text={getReadonlyLessonText(lesson.status)} />
              )}

              {currentStep === 1 && (
                <>
                  <LessonHeader readOnly={readOnly} />
                  <KindSettings setCurrentStep={setCurrentStep} />
                </>
              )}

              {currentStep === 2 && <FigmaFileSettings />}

              {currentStep === 3 && (
                <>
                  <LessonHeader readOnly={readOnly} />
                  {currentTask === 'BY_TASK_DESCRIPTION' && (
                    <TaskByDescriptionSettings />
                  )}
                  {currentTask !== 'GOOD_TO_KNOW' &&
                    currentTask !== 'BY_REFERENCE' && (
                      <MainSettings
                        figmaFileImages={figmaFileImages}
                        currentTask={
                          currentTask === 'BY_TASK_DESCRIPTION'
                            ? 0
                            : currentTask
                        }
                        designType={values.designType}
                      />
                    )}
                  {currentTask === 'BY_REFERENCE' && (
                    <TaskByReferenceSettings />
                  )}
                  {currentTask === 'GOOD_TO_KNOW' && <GoodToKnowSettings />}
                </>
              )}
            </>
          )}
        </div>
      </LessonLayout>
    )}
  </Formik>
);

export default React.memo(FigmaLessonPage);
