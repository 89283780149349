import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { updateLessonById } from 'store/lesson/actions';

import KindSettings from './KindSettings';

const KindSettingsContainer = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const { values } = useFormikContext();

  const { lessonId } = useParams();

  const handleOptionClick = (option) => {
    try {
      dispatch(
        updateLessonById({
          lessonId,
          lessonType: values.type,
          data: { kind: option.value },
          changedPropertiesKeys: ['kind'],
        }),
      );

      setCurrentStep(2);
    } catch (error) {
      console.warn(error);
    }
  };

  return <KindSettings onOptionClick={handleOptionClick} />;
};

export default React.memo(KindSettingsContainer);
