import { v4 as uuid } from 'uuid';
import _orderBy from 'lodash/orderBy';

import { FIGMA_LAYER_TYPES } from 'constants/common';

import { getFramesPreviews } from 'helpers/getFramesPreviews';

import { getTaskMaterials } from './getTaskMaterials';
import { transformLayers } from './transformLayers';

export const getDesignTasks = async ({
  fileId,
  filePage,
  fileImages,
  currentTasks,
}) => {
  const tasksLayers = filePage.children.filter(
    (layer) =>
      layer.type === FIGMA_LAYER_TYPES.SECTION &&
      /Task\s[0-9]+$/g.test(layer.name),
  );

  if (!tasksLayers || tasksLayers.length === 0) {
    return [];
  }

  const tasksPreviews = await getFramesPreviews({
    fileId,
    layers: tasksLayers,
  });

  const tasks = _orderBy(
    tasksLayers.map((tasksLayer) => {
      const currentTask = currentTasks.find(
        (task) => task.name === tasksLayer.name,
      );

      const layers = transformLayers({
        layers: tasksLayer.children,
        currentLayers: currentTask ? currentTask.layers : [],
        parent: null,
      });

      const currentTaskData = currentTask
        ? {
            withTheory: currentTask.withTheory,
            withHints: currentTask.withHints,
            withMaterials: currentTask.withMaterials,
            checkStyles: currentTask.checkStyles,
            theoryBlocks: currentTask.theoryBlocks,
            hintsBlocks: currentTask.hintsBlocks,
            materials: getTaskMaterials({
              fileImages,
              taskLayers: layers,
            }).map((fileImage) => {
              const currentMaterial = currentTask.materials.find(
                (material) => material.imageRef === fileImage.imageRef,
              );
              if (!currentMaterial) {
                return {
                  ...fileImage,
                  check: false,
                };
              }
              return {
                ...currentMaterial,
                imageUrl: fileImage.imageUrl,
              };
            }),
            text: currentTask.text,
          }
        : {
            withTheory: false,
            withHints: false,
            withMaterials: false,
            checkStyles: false,
            theoryBlocks: [],
            hintsBlocks: [],
            materials: getTaskMaterials({
              fileImages,
              taskLayers: layers,
            }).map((fileImage) => ({
              ...fileImage,
              check: false,
            })),
            text: '',
          };

      return {
        id: uuid(),
        name: tasksLayer.name,
        preview: tasksPreviews[tasksLayer.id],
        layers,
        ...currentTaskData,
      };
    }),
    (task) => Number(task.name.split(' ')[1]),
    ['asc'],
  );

  return tasks;
};
