import React from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import { useCourse } from 'hooks/useCourse';

import { MarketingSettings } from './components/MarketingSettings';
import { PaymentSettings } from './components/PaymentSettings';
import { CourseSettings } from './components/CourseSettings';

import CoursePage from './CoursePage';

const CoursePageContainer = () => {
  const {
    loading,
    saveLoading,
    autoSaveLoading,
    readOnly,
    marketingSettingsOpen,
    paymentSettingsOpen,
    courseSettingsOpen,
    data: course,
    lastActionAt,
    closeMarketingSettings,
    closePaymentSettings,
    closeCourseSettings,
    onAutoSaveSubmit,
    onSaveClick,
    onPreviewClick,
    onStatusContextItemClick,
    onMoreContextItemClick,
  } = useCourse();

  const user = useSelector(userSelector);
  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  return (
    <>
      <CoursePage
        loading={loading}
        isRootAdmin={isRootAdmin}
        saveLoading={saveLoading}
        autoSaveLoading={autoSaveLoading}
        readOnly={readOnly}
        course={course}
        lastActionAt={lastActionAt}
        onAutoSaveSubmit={onAutoSaveSubmit}
        onSaveClick={onSaveClick}
        onPreviewClick={onPreviewClick}
        onMoreContextItemClick={onMoreContextItemClick}
        onStatusContextItemClick={onStatusContextItemClick}
      />
      <MarketingSettings
        open={marketingSettingsOpen}
        onClose={closeMarketingSettings}
      />
      <PaymentSettings
        open={paymentSettingsOpen}
        onClose={closePaymentSettings}
      />
      <CourseSettings open={courseSettingsOpen} onClose={closeCourseSettings} />
    </>
  );
};

export default React.memo(CoursePageContainer);
