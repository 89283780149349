import React, { useMemo, useCallback } from 'react';
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import isHotkey from 'is-hotkey';

import { Element } from './components/Element';
import { Leaf } from './components/Leaf';

import { HOTKEYS, TEXT_ALIGN_TYPES } from './EditorInput.constants';

import {
  withLinks,
  wrapLink,
  isMarkActive,
  isBlockActive,
} from './EditorInput.helpers';

import EditorInput from './EditorInput';

const EditorInputContainer = ({ name, value, onBlur, ...restProps }) => {
  const currentEditor = useMemo(
    () => withLinks(withReact(withHistory(createEditor())), true),
    [],
  );

  const _toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const _toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
    );
    const isList = false;

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });

    let newProperties = null;

    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };

      Transforms.wrapNodes(editor, block);
    }
  };

  const handleMarkIconClick = (format) => {
    _toggleMark(currentEditor, format);
  };

  const handleBlockIconClick = (format) => {
    _toggleBlock(currentEditor, format);
  };

  const handleLinkIconClick = () => {
    // eslint-disable-next-line no-alert
    const url = window.prompt('Enter the URL of the link:');

    if (!url) {
      return;
    }

    if (currentEditor.selection) {
      wrapLink(currentEditor, url);
    }
  };

  //   const handleFocus = (event) => {
  //     if (editor) {
  //       ReactEditor.focus(editor);
  //     }
  //   };

  //   const handleBlur = (event) => {
  //     if (onBlur) {
  //       onBlur(name)(event);
  //     }
  //   };

  const handleKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();

        const mark = HOTKEYS[hotkey];

        _toggleMark(currentEditor, mark);
      }
    }
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <EditorInput
      {...restProps}
      editor={currentEditor}
      value={value}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      onKeyDown={handleKeyDown}
      //   onFocus={handleFocus}
      //   onBlur={handleBlur}
      onMarkIconClick={handleMarkIconClick}
      onLinkIconClick={handleLinkIconClick}
      onBlockIconClick={handleBlockIconClick}
    />
  );
};

export default React.memo(EditorInputContainer);
