import GroupIcon from 'assets/icons/layers/group.svg'; // TODO: draw from denis
import RectangleIcon from 'assets/icons/layers/rectangle.svg';
import EllipseIcon from 'assets/icons/layers/ellipse.svg';
import LineIcon from 'assets/icons/layers/line.svg';
import StarIcon from 'assets/icons/layers/star.svg';
import PolygonIcon from 'assets/icons/layers/polygon.svg';
import TextIcon from 'assets/icons/layers/text.svg';
import ComponentIcon from 'assets/icons/layers/component.svg';
import InstanceIcon from 'assets/icons/layers/instance.svg';
import FrameIcon from 'assets/icons/layers/frame.svg';
import VectorIcon from 'assets/icons/layers/vector.svg';
import ExcludeIcon from 'assets/icons/layers/exclude.svg';
import UnionIcon from 'assets/icons/layers/union.svg';
import SubtractIcon from 'assets/icons/layers/subtract.svg';
import IntersectIcon from 'assets/icons/layers/intersect.svg';
import VariantIcon from 'assets/icons/layers/variant.svg';
import AutoLayoutHorizontalTop from 'assets/icons/layers/layout-horizontal-top.svg';
import AutoLayoutHorizontalCenter from 'assets/icons/layers/layout-horizontal-center.svg';
import AutoLayoutHorizontalBottom from 'assets/icons/layers/layout-horizontal-bottom.svg';
import AutoLayoutVerticalLeft from 'assets/icons/layers/layout-vertical-left.svg';
import AutoLayoutVerticalCenter from 'assets/icons/layers/layout-vertical-center.svg';
import AutoLayoutVerticalRight from 'assets/icons/layers/layout-vertical-right.svg';
import AutoLayoutWrapLeft from 'assets/icons/layers/layout-wrap-left.svg';
import AutoLayoutWrapRight from 'assets/icons/layers/layout-wrap-right.svg';
import AutoLayoutWrapCenter from 'assets/icons/layers/layout-wrap-center.svg';

const _getBooleanOperationLayerSubTypeIcon = (subType) => {
  switch (subType) {
    case 'EXCLUDE': {
      return ExcludeIcon;
    }
    case 'UNION': {
      return UnionIcon;
    }
    case 'SUBTRACT': {
      return SubtractIcon;
    }
    case 'INTERSECT': {
      return IntersectIcon;
    }
    case 'VARIANT': {
      return VariantIcon;
    }
    default: {
      return null;
    }
  }
};

const _getComponentLayerSubTypeIcon = (subType) => {
  switch (subType) {
    case 'VARIANT': {
      return VariantIcon;
    }
    default: {
      return null;
    }
  }
};

const _getFrameLayerSubTypeIcon = (subType) => {
  switch (subType) {
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_RIGHT': {
      return AutoLayoutHorizontalTop;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_RIGHT': {
      return AutoLayoutHorizontalCenter;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_RIGHT': {
      return AutoLayoutHorizontalBottom;
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_LEFT':
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_LEFT':
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_LEFT': {
      return AutoLayoutVerticalLeft;
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_CENTER':
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_CENTER':
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_CENTER': {
      return AutoLayoutVerticalCenter;
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_RIGHT':
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_RIGHT':
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_RIGHT': {
      return AutoLayoutVerticalRight;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_LEFT': {
      return AutoLayoutWrapLeft;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_CENTER': {
      return AutoLayoutWrapCenter;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_RIGHT': {
      return AutoLayoutWrapRight;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT': {
      return AutoLayoutHorizontalTop;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT': {
      return AutoLayoutWrapLeft;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER': {
      return AutoLayoutWrapCenter;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return AutoLayoutWrapRight;
    }
  }
};

export const getLayerTypeIcon = (type, subType) => {
  switch (type) {
    case 'FRAME': {
      if (subType) {
        return _getFrameLayerSubTypeIcon(subType);
      }

      return FrameIcon;
    }
    case 'SECTION': {
      return FrameIcon; // TODO
    }
    case 'GROUP': {
      return GroupIcon;
    }
    case 'RECTANGLE': {
      return RectangleIcon;
    }
    case 'ELLIPSE': {
      return EllipseIcon;
    }
    case 'LINE': {
      return LineIcon;
    }
    case 'STAR': {
      return StarIcon;
    }
    case 'REGULAR_POLYGON': {
      return PolygonIcon;
    }
    case 'TEXT': {
      return TextIcon;
    }
    case 'COMPONENT': {
      if (subType) {
        return _getComponentLayerSubTypeIcon(subType);
      }

      return ComponentIcon;
    }
    case 'COMPONENT_SET': {
      return ComponentIcon;
    }
    case 'INSTANCE': {
      return InstanceIcon;
    }
    case 'BOOLEAN_OPERATION': {
      return _getBooleanOperationLayerSubTypeIcon(subType);
    }
    case 'VECTOR': {
      return VectorIcon;
    }
  }
};
