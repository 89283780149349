import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';

import { initialValues, getValidationSchema } from './AddAdmin.formConfig';

import styles from './AddAdmin.styles.scss';

const AddAdmin = ({
  open,
  isRootAdmin,
  isSchool,
  inviteLoading,
  schoolOptions,
  onClose,
  onInviteAdminClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('components.add_admin.header_text')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema(isRootAdmin)}
      onSubmit={onInviteAdminClick}
    >
      <Form className={styles.form}>
        <div className={styles.main}>
          <FormikField
            name="email"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('components.add_admin.email'),
            }}
          />
          <FormikField
            name="firstName"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('components.add_admin.first_name'),
            }}
          />
          <FormikField
            name="lastName"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('components.add_admin.last_name'),
            }}
          />
          {isRootAdmin && !isSchool && (
            <FormikField
              name="school"
              component={SelectField}
              componentProps={{ options: schoolOptions }}
              controlProps={{
                required: true,
                label: getTranslation('components.add_admin.school'),
              }}
            />
          )}
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              inviteLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            {getTranslation('common.button.invite')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(AddAdmin);
