import React from 'react';

import { LessonPreviewLayout } from 'components/Layout';

import { Toolbar } from './components/Toolbar';
import { WebPreviewPage } from './components/WebPreviewPage';
import { PluginPreviewPage } from './components/PluginPreviewPage';

import styles from './TheoryLessonPreviewPage.styles.scss';

const TheoryLessonPreviewPage = ({
  lesson,
  previewType,
  setPreviewType,
  isTestMode,
  setTestMode,
  lessonTitle,
  currentLesson,
  lessonsCount,
  ...props
}) => {
  return (
    <LessonPreviewLayout
      isTheoryLesson={true}
      setPreviewType={setPreviewType}
      setTestMode={setTestMode}
      toolbarComponent={
        <div className={styles.toolbar}>
          <Toolbar
            lessonTitle={lessonTitle}
            currentLesson={currentLesson}
            lessonsCount={lessonsCount}
          />
        </div>
      }
    >
      {previewType === 'Plugin' ? (
        <PluginPreviewPage {...props} lesson={lesson} isTestMode={isTestMode} />
      ) : (
        <WebPreviewPage {...props} lesson={lesson} isTestMode={isTestMode} />
      )}
    </LessonPreviewLayout>
  );
};

export default React.memo(TheoryLessonPreviewPage);
