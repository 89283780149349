import { transformLayers } from './transformLayers';

export const getPrototypeInitialState = ({ filePage, currentInitialState }) => {
  const layers = filePage.children;

  const initialState = {
    preview: null,
    componentsLayer: null,
    layers: transformLayers({
      layers,
      currentLayers: currentInitialState ? currentInitialState.layers : [],
      parent: null,
    }),
  };

  return initialState;
};
