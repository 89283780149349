import React from 'react';

import { formatDate } from 'helpers/formatDate';
import { getStatusText } from 'helpers/getStatusText';
import { getTranslation } from 'helpers/getTranslation';
import { getUserStatusVariant } from 'helpers/getUserStatusVariant';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { StatusTag } from 'components/StatusTag';
import { SchoolPreview } from 'components/SchoolPreview';

import styles from './SchoolCard.styles.scss';

const SchoolCard = ({
  withStatus,
  withEdit,
  preview,
  name,
  status,
  createdAt,
  locale,
  onEditClick,
}) => (
  <div className={styles.card}>
    <div className={styles.inner}>
      <SchoolPreview
        className={styles.preview}
        size="lg"
        preview={preview}
        name={name}
      />
      <div className={styles.main}>
        <Typography
          className={styles.name}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {name || '-'}
        </Typography>
        {withStatus && (
          <StatusTag
            className={styles.status}
            variant={getUserStatusVariant(status)}
          >
            {getStatusText(status)}
          </StatusTag>
        )}
      </div>
    </div>
    <div className={styles.info}>
      <Typography className={styles.createdAt} variant="body3" mode="compact">
        {getTranslation('school_page.school_card.start_date')}:{' '}
        {formatDate(createdAt, 'dd MMM, yyyy', locale)}
      </Typography>
      {withEdit && (
        <div className={styles.actions}>
          <Button
            className={styles.action}
            startIcon={<Icon name="edit" />}
            size="md"
            variant="outlined"
            onClick={onEditClick}
          >
            {getTranslation('common.button.edit')}
          </Button>
        </div>
      )}
    </div>
  </div>
);

SchoolCard.defaultProps = {
  withStatus: true,
  withEdit: true,
};

export default React.memo(SchoolCard);
