import { FIGMA_LAYER_TYPES } from 'constants/common';

const _getLayerParentPosition = (parent, type) => {
  if (
    !parent ||
    (parent.type !== FIGMA_LAYER_TYPES.GROUP &&
      parent.type !== FIGMA_LAYER_TYPES.BOOLEAN_OPERATION)
  ) {
    return 0;
  }

  switch (type) {
    case 'x': {
      return (
        Number(parent.relativeTransform[0][2].toFixed(2)) +
        _getLayerParentPosition(parent.parent, type)
      );
    }
    case 'y': {
      return (
        Number(parent.relativeTransform[1][2].toFixed(2)) +
        _getLayerParentPosition(parent.parent, type)
      );
    }
  }
};

const _getLayerPosition = (layer, type) => {
  switch (type) {
    case 'x': {
      const layerXPosition = Number(layer.relativeTransform[0][2].toFixed(2));

      return layerXPosition + _getLayerParentPosition(layer.parent, type);
    }
    case 'y': {
      const layerYPosition = Number(layer.relativeTransform[1][2].toFixed(2));

      return layerYPosition + _getLayerParentPosition(layer.parent, type);
    }
  }
};

export const getLayerBoundingBox = (layer) => ({
  width: Number(layer.size.x.toFixed(2)),
  height: Number(layer.size.y.toFixed(2)),
  x: _getLayerPosition(layer, 'x'),
  y: _getLayerPosition(layer, 'y'),
});
