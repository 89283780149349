import React from 'react';

import { Typography } from 'components/shared/Typography';

import { getPropertyValue, getBackground } from './ColorProperty.helpers';

import styles from './ColorProperty.styles.scss';

const _renderContent = (property) => {
  return (
    <>
      {property.valueType === 'IMAGE' && (
        <div
          className={styles.color}
          style={{
            background: getBackground({
              type: property.valueType,
              value: property.value,
            }),
            transform: `rotate(${property.value.rotation}deg)`,
          }}
        />
      )}
      {property.valueType !== 'IMAGE' && property.valueType !== 'VIDEO' && (
        <div
          className={styles.color}
          style={{
            background: getBackground({
              type: property.valueType,
              value: property.value,
            }),
          }}
        />
      )}

      <Typography
        className={styles.value}
        variant="body2"
        weight="compact"
        mode="compact"
      >
        {getPropertyValue({ type: property.valueType, value: property.value })}
      </Typography>
    </>
  );
};

const ColorProperty = ({ isInitialState, readOnly, property }) => {
  if (isInitialState || readOnly) {
    return (
      <div className={styles.propertyReadOnly}>{_renderContent(property)}</div>
    );
  }

  return null;
};

export default React.memo(ColorProperty);
