import { getAutoLayoutDirectionKey } from 'helpers/getAutoLayoutDirectionKey';

export const getLayerTypeIconClassName = (layer) => {
  switch (layer.type) {
    case 'FRAME':
    case 'GROUP':
    case 'RECTANGLE':
    case 'ELLIPSE':
    case 'LINE':
    case 'STAR':
    case 'REGULAR_POLYGON':
    case 'TEXT':
    case 'BOOLEAN_OPERATION': {
      const parent = layer.properties.find(
        (property) => property.key === 'parent',
      );

      if (!parent || !parent.value) {
        return 'default';
      }

      if (
        parent.value.type === 'COMPONENT' ||
        parent.value.type === 'COMPONENT_SET' ||
        parent.value.type === 'INSTANCE'
      ) {
        return 'purple';
      }

      return 'default';
    }

    case 'COMPONENT':
    case 'COMPONENT_SET':
    case 'INSTANCE': {
      return 'purple';
    }
    case 'SECTION': {
      return 'default';
    }
  }
};

export const getLayerSubType = (layer) => {
  switch (layer.type) {
    case 'BOOLEAN_OPERATION': {
      const booleanOperation = layer.properties.find(
        (property) => property.key === 'booleanOperation',
      );

      return booleanOperation.value;
    }
    case 'COMPONENT': {
      const parent = layer.properties.find(
        (property) => property.key === 'parent',
      );

      if (!parent || !parent.value) {
        return null;
      }

      if (parent.value.type === 'COMPONENT_SET') {
        return 'VARIANT';
      }

      return null;
    }
    case 'FRAME': {
      const counterAxisAlignItems = layer.properties.find(
        (property) => property.key === 'counterAxisAlignItems',
      );
      const primaryAxisAlignItems = layer.properties.find(
        (property) => property.key === 'primaryAxisAlignItems',
      );
      const layoutMode = layer.properties.find(
        (property) => property.key === 'layoutMode',
      );
      const layoutWrap = layer.properties.find(
        (property) => property.key === 'layoutWrap',
      );

      if (!layoutMode || !layoutMode.value) {
        return null;
      }

      return getAutoLayoutDirectionKey({
        layoutMode: layoutMode.value,
        layoutWrap: layoutWrap.value,
        counterAxisAlignItems: counterAxisAlignItems.value,
        primaryAxisAlignItems: primaryAxisAlignItems.value,
      });
    }
    default: {
      return null;
    }
  }
};

export const getLayerCheck = (layer) =>
  layer.properties.some((property) => property.check);
