import * as api from 'api/methods';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { getFigmaFileImages } from './getFigmaFileImages';
import { getTasksMaterials } from './getTasksMaterials';
import { getDesignInitialState } from './getDesignInitialState';
import { getDesignTasks } from './getDesignTasks';
import { getPrototypeInitialState } from './getPrototypeInitialState';
import { getPrototypeTasks } from './getPrototypeTasks';
import { getFigmaFileStyles } from './getFigmaFileStyles';

const _getFileDesignType = (filePage) => {
  const type = filePage.name.split(':')[0];

  if (type === 'Design') {
    return 'DESIGN';
  }

  if (type === 'Prototype') {
    return 'PROTOTYPE';
  }

  return null;
};

export const getResyncData = async ({
  fileId,
  filePageId,
  currentInitialState,
  currentTasks,
  designType,
}) => {
  try {
    const file = await api.getFigmaFile(fileId);
    const fileImages = await getFigmaFileImages(fileId);

    const filePage = file.document.children.find(
      (page) => page.id === filePageId,
    );

    const fileDesignType = _getFileDesignType(filePage);

    if (designType && fileDesignType !== designType) {
      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: 'Resync error',
        },
      });

      return null;
    }

    switch (fileDesignType) {
      case 'DESIGN': {
        const { initialState } = await getDesignInitialState({
          fileId,
          filePage,
          currentInitialState,
        });

        if (!initialState) {
          showToast({
            data: {
              variant: 'error',
              title: 'Error!',
              text: 'Resync error',
            },
          });

          return null;
        }

        const tasks = await getDesignTasks({
          fileId,
          filePage,
          fileImages,
          currentTasks,
        });

        if (!tasks || tasks.length === 0) {
          showToast({
            data: {
              variant: 'error',
              title: 'Error!',
              text: 'Resync error',
            },
          });

          return null;
        }

        return [
          tasks,
          initialState,
          fileDesignType,
          getTasksMaterials({ fileImages, tasks, initialState }),
        ];
      }
      case 'PROTOTYPE': {
        const initialState = getPrototypeInitialState({
          filePage,
          currentInitialState,
        });

        const tasks = getPrototypeTasks({ filePage, currentTasks });

        return [
          tasks,
          initialState,
          fileDesignType,
          getTasksMaterials({ fileImages, tasks, initialState }),
        ];
      }
    }
  } catch (error) {
    console.warn(error);

    showToast({
      data: {
        variant: 'error',
        title: getTranslation('common.toast.error.title'),
        text: error.message || getTranslation('common.toast.error.text'),
      },
    });
  }

  return null;
};
