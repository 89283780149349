import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { STATUSES } from 'constants/common';

import { showToast } from 'helpers/showToast';
import { getLessonPathByCourseType } from 'helpers/getLessonPathByCourseType';

import { useStatusChange } from 'hooks/useStatusChange';

import { validateCourseLesson } from './LessonItem.helpers';

import LessonItem from './LessonItem';

const LessonItemContainer = ({ id, courseId, type, ...restProps }) => {
  const navigate = useNavigate();

  const { values, setFieldValue } = useFormikContext();

  const [onStatusContextItemClick] = useStatusChange({ id, type });

  const handleClick = () => {
    navigate(
      getLessonPathByCourseType({
        courseType: values.type,
        courseId,
        lessonId: id,
      }),
    );
  };

  const handleStatusContextItemClick = ({ contextItem, lessonId }) => {
    const { valid, errorText } = validateCourseLesson(
      { lessonId, values },
      { setFieldValue },
    );

    if (contextItem.value === STATUSES.PUBLISHED && !valid) {
      showToast({
        data: {
          variant: 'warning',
          title: 'Warning!',
          text: errorText,
        },
      });

      return;
    }

    onStatusContextItemClick(contextItem);
  };

  return (
    <LessonItem
      {...restProps}
      id={id}
      type={type}
      onClick={handleClick}
      onStatusContextItemClick={handleStatusContextItemClick}
    />
  );
};

export default React.memo(LessonItemContainer);
