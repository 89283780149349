import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { STATUSES } from 'constants/common';

import * as api from 'api/methods';

import { updateLessonById } from 'store/lesson/actions';

import { currentCourseSelector } from 'store/course/selectors';
import { currentLessonSelector } from 'store/lesson/selectors';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';
import { getChangedPropertiesByKeys } from 'helpers/getChangedPropertiesByKeys';

import { useStatusChange } from 'hooks/useStatusChange';

import {
  convertDataToApi,
  getChangedProperties,
} from './ColorMatchLessonPage.helpers';

import ColorMatchLessonPage from './ColorMatchLessonPage';

const ColorMatchLessonPageContainer = (props) => {
  const lesson = useSelector(currentLessonSelector);

  const [saveLoading, setSaveLoading] = useState(false);
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);
  const [lastActionAt, setLastActionAt] = useState(lesson.updatedAt);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { courseId, lessonId } = useParams();

  const course = useSelector(currentCourseSelector);

  const [onStatusContextItemClick] = useStatusChange(lesson);

  const handleStatusContextItemClick = (
    contextItem,
    values,
    // { setFieldValue },
  ) => {
    // const setValidationMessage = (message) => {
    //   setFieldValue('statusError', message);

    //   showToast({
    //     data: {
    //       variant: 'warning',
    //       title: 'Warning!',
    //       text: message,
    //     },
    //   });
    // };

    // if (contextItem.key === STATUSES.PUBLISHED) {
    //   if (!checkCorrectAnswers(values)) {
    //     setValidationMessage(
    //       'Check answers, at least one answer should be marked as correct',
    //     );

    //     return;
    //   }

    //   if (!checkAnswersValue(values)) {
    //     setValidationMessage('Check answers, every answer must be filled');

    //     return;
    //   }
    // }

    onStatusContextItemClick(contextItem, convertDataToApi(values));
  };

  const handleAutoSaveSubmit = async (values) => {
    if (lessonId !== values.id) {
      return;
    }

    const data = convertDataToApi(values);

    const changedPropertiesKeys = getChangedProperties({
      currentLesson: lesson,
      nextLesson: data,
    });

    if (changedPropertiesKeys && changedPropertiesKeys.length > 0) {
      setAutoSaveLoading(true);

      const updatedLesson = await api.updateColorMatchLessonById({
        lessonId,
        data: getChangedPropertiesByKeys({ data, keys: changedPropertiesKeys }),
      });

      setLastActionAt(updatedLesson.updatedAt);

      setAutoSaveLoading(false);
    }
  };

  const handleSaveClick = async (values) => {
    setSaveLoading(true);

    try {
      const data = convertDataToApi(values);

      const changedPropertiesKeys = getChangedProperties({
        currentLesson: lesson,
        nextLesson: data,
      });

      const updatedLesson = await dispatch(
        updateLessonById({
          lessonId,
          lessonType: lesson.type,
          data,
          changedPropertiesKeys,
        }),
      ).unwrap();

      setLastActionAt(updatedLesson.updatedAt);

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.lesson_saved.title'),
          text: getTranslation('common.toast.lesson_saved.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }

    setSaveLoading(false);
  };

  const handlePreviewClick = () => {
    navigate(`/courses/${courseId}/lessons/${lessonId}/preview`);
  };

  const lessonReadOnly = lesson.status !== STATUSES.DRAFT;

  return (
    <ColorMatchLessonPage
      {...props}
      saveLoading={saveLoading}
      autoSaveLoading={autoSaveLoading}
      readOnly={lessonReadOnly}
      lesson={lesson}
      lastActionAt={lastActionAt}
      courseTitle={course.title || getTranslation('common.text.untitled')}
      courseStatus={course.status}
      onAutoSaveSubmit={handleAutoSaveSubmit}
      onSaveClick={handleSaveClick}
      onPreviewClick={handlePreviewClick}
      onStatusContextItemClick={handleStatusContextItemClick}
    />
  );
};

export default React.memo(ColorMatchLessonPageContainer);
