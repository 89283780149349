import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { FormikField } from 'components/FormikField';
import { EditableTextField, SelectField } from 'components/FormikFields';

import { typeOptions } from './BlockWithValue.config';

import styles from './BlockWithValue.styles.scss';

const BlockWithValue = ({ readOnly, namePrefix }) => (
  <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
    <div className={styles.inner}>
      <FormikField
        name={`${namePrefix}.data.type`}
        withControl={false}
        component={SelectField}
        componentProps={{
          className: styles.type,
          options: typeOptions,
          readOnly,
        }}
      />
      <FormikField
        name={`${namePrefix}.data.value`}
        withControl={false}
        component={EditableTextField}
        componentProps={{
          className: styles.value,
          readOnly,
          variant: 'text',
          placeholder: getTranslation(
            'components.theory_blocks.list_placeholder',
          ),
        }}
      />
    </div>
  </div>
);

export default React.memo(BlockWithValue);
