import React from 'react';

import { LessonPreviewLayout } from 'components/Layout';
import { WebPreviewPage } from './components/WebPreviewPage';
import { PluginPreviewPage } from './components/PluginPreviewPage';

const TwoColumnsLessonPreviewPage = ({
  correct,
  submitted,
  allChecked,
  variants,
  leftItems,
  rightItems,
  draggingItem,
  lesson,
  onItemDragStart,
  onItemDragEnd,
  onItemDragCancel,
  onTryAgainClick,
  onRestartLessonClick,
  onCheckClick,
  previewType,
  setPreviewType,
  isTestMode,
  setTestMode,
  ...props
}) => {
  return (
    <LessonPreviewLayout
      setPreviewType={setPreviewType}
      setTestMode={setTestMode}
    >
      {previewType === 'Plugin' ? (
        <PluginPreviewPage
          {...props}
          correct={correct}
          submitted={submitted}
          allChecked={allChecked}
          variants={variants}
          leftItems={leftItems}
          rightItems={rightItems}
          lesson={lesson}
          draggingItem={draggingItem}
          onItemDragStart={onItemDragStart}
          onItemDragEnd={onItemDragEnd}
          onItemDragCancel={onItemDragCancel}
          onTryAgainClick={onTryAgainClick}
          onRestartLessonClick={onRestartLessonClick}
          onCheckClick={onCheckClick}
          isTestMode={isTestMode}
        />
      ) : (
        <WebPreviewPage
          correct={correct}
          submitted={submitted}
          allChecked={allChecked}
          variants={variants}
          leftItems={leftItems}
          rightItems={rightItems}
          lesson={lesson}
          draggingItem={draggingItem}
          onItemDragStart={onItemDragStart}
          onItemDragEnd={onItemDragEnd}
          onItemDragCancel={onItemDragCancel}
          onTryAgainClick={onTryAgainClick}
          onRestartLessonClick={onRestartLessonClick}
          onCheckClick={onCheckClick}
          isTestMode={isTestMode}
        />
      )}
    </LessonPreviewLayout>
  );
};

export default React.memo(TwoColumnsLessonPreviewPage);
