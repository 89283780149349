import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { UploadField } from 'components/FormikFields';
import { InputField } from 'components/FormikFields';

import { validationSchema } from './MarketingSettings.formConfig';

import styles from './MarketingSettings.styles.scss';

const MarketingSettings = ({
  open,
  saveLoading,
  course,
  onClose,
  onSaveClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('course_page.marketing_settings.header')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={{
        socialPreview: course?.socialPreview,
        order: (course && course.order) || 0,
      }}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={onSaveClick}
    >
      <Form className={styles.form}>
        <div className={styles.inner}>
          <FormikField
            name="socialPreview"
            component={UploadField}
            controlProps={{
              label: getTranslation(
                'course_page.marketing_settings.preview_label',
              ),
            }}
            componentProps={{
              type: 'input',
              variant: 'imageBlock',
              className: styles.uploadField,
              accept: {
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
              },
            }}
          />
          <FormikField
            name="order"
            component={InputField}
            controlProps={{
              label: getTranslation(
                'course_page.marketing_settings.order_label',
              ),
            }}
            componentProps={{
              value: (course && course.order) || 0,
            }}
          />
        </div>

        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              saveLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            {getTranslation('common.button.save')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(MarketingSettings);
